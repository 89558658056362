var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-list-component table-component" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c(
          "div",
          { staticClass: "page-breadcrumb" },
          [
            _c(
              "a-breadcrumb",
              [
                _c(
                  "a-breadcrumb-item",
                  [_c("router-link", { attrs: { to: "/" } }, [_vm._v("首页")])],
                  1
                ),
                _c("a-breadcrumb-item", [_vm._v("批量升级")]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "page-body page-body-margin" },
        [
          _c(
            "a-layout",
            [
              _c("a-layout-content", [
                _c(
                  "div",
                  { staticClass: "page-body-margin-left-16" },
                  [
                    _c(
                      "a-card",
                      {
                        staticClass: "table-query-card",
                        attrs: { title: "查询" },
                      },
                      [
                        _c("jtl-table-query-component", {
                          ref: _vm.JTL.CONSTANT.DEFAULT_TABLE_QUERY_NAME,
                          on: { search: _vm.searchClick },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-card",
                      { attrs: { title: "列表" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "table-header-button",
                            attrs: { slot: "extra" },
                            slot: "extra",
                          },
                          [
                            _c(
                              "jtl-button",
                              {
                                attrs: {
                                  "table-header-child": "",
                                  type: "default",
                                  "click-prop": _vm.downloadTemplate,
                                },
                              },
                              [_vm._v("下载模板")]
                            ),
                            _c(
                              "jtl-button",
                              {
                                attrs: {
                                  "table-header-child": "",
                                  type: "primary",
                                  "click-prop": _vm.exportTemplate,
                                },
                              },
                              [_vm._v("导入")]
                            ),
                            _c(
                              "jtl-button",
                              {
                                attrs: {
                                  "table-header-child": "",
                                  type: "primary",
                                  "click-prop": _vm.addClickHander,
                                },
                              },
                              [_vm._v("添加")]
                            ),
                          ],
                          1
                        ),
                        _c("a-table", {
                          attrs: {
                            loading: _vm.listLoading,
                            columns: _vm.tableColumns,
                            "data-source": _vm.listData,
                            pagination: _vm.Pagination,
                            scroll: _vm.tableScroll,
                            "row-key": _vm.TableRowKey,
                            size: _vm.TableSize,
                          },
                          on: { change: _vm.tableChange },
                          scopedSlots: _vm._u([
                            {
                              key: "status",
                              fn: function (text) {
                                return [
                                  _c(
                                    "span",
                                    {
                                      style: { color: _vm.getTextColor(text) },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          text === "SUBMITTED"
                                            ? "已提交"
                                            : "未提交"
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "action",
                              fn: function (text, record) {
                                return [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "jtl-link",
                                      on: {
                                        click: function ($event) {
                                          return _vm.showClickHandler(record)
                                        },
                                      },
                                    },
                                    [_vm._v("查看")]
                                  ),
                                  record.status !== "SUBMITTED"
                                    ? [
                                        _c("a-divider", {
                                          attrs: { type: "vertical" },
                                        }),
                                        _c(
                                          "a",
                                          {
                                            staticClass: "jtl-edit-link",
                                            on: {
                                              click: function ($event) {
                                                return _vm.editClickHandler(
                                                  record
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("编辑")]
                                        ),
                                        _c("a-divider", {
                                          attrs: { type: "vertical" },
                                        }),
                                        _c(
                                          "a-popconfirm",
                                          {
                                            attrs: {
                                              title: "确认提交?",
                                              "ok-text": "确认",
                                              "cancel-text": "取消",
                                            },
                                            on: {
                                              confirm: function ($event) {
                                                return _vm.submitClick(record)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "a",
                                              { staticClass: "jtl-edit-link" },
                                              [_vm._v("提交")]
                                            ),
                                          ]
                                        ),
                                        _c("a-divider", {
                                          attrs: { type: "vertical" },
                                        }),
                                        _c(
                                          "a-popconfirm",
                                          {
                                            attrs: {
                                              title: "确认删除?",
                                              "ok-text": "确认",
                                              "cancel-text": "取消",
                                            },
                                            on: {
                                              confirm: function ($event) {
                                                return _vm.deleteClick(record)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "a",
                                              { staticClass: "jtl-del-link" },
                                              [_vm._v("删除")]
                                            ),
                                          ]
                                        ),
                                      ]
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("jtl-entity-dialog", {
        ref: "formDialog",
        on: { dialogOK: _vm.formDialogOK },
      }),
      _c("import-add-update-dialog", {
        ref: "importAppUpdateDialog",
        on: { dialogOK: _vm.formDialogOK },
      }),
      _c("app-updata-add-dialog", {
        ref: "appUpdataAddDialog",
        on: { dialogOK: _vm.formDialogOK },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }


















import { Component } from 'vue-property-decorator';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import { ICRUDQ } from '@common-src/model/interface';
import { ViewModeType } from '@common-src/model/enum';
import { AppBatchUpdateService } from '@common-src/service3/app-batch-update';
import { FormControlType } from '@common-src/model/form-control';

@Component
export default class ImportAppUpdateDialog extends FormDialogComponent<any> {
    dialogOpen(): void {
        this.dialogVisible = true;
        this.viewMode = ViewModeType.NEW;
        this.dialogTitle = '导入';
        this.$nextTick(() => {
            if (this.jtlForm) {
                // this.jtlForm.initForm(importModel, viewMode);
                this.jtlForm.initFormByControls([{
                    key: 'remark',
                    label: '升级说明',
                    type: FormControlType.TEXT_AREA,
                    maxLength: 100,
                    required: true
                }, {
                    key: 'file',
                    label: '上传文件',
                    type: FormControlType.UPLOAD,
                    accept: '.xlsx,.xls',
                    maxFileNumber: 1,
                    notUploadOss: true,
                    required: true
                }] as any);
            }
        });
    }

    dialogOK(): any {
        if (!this.jtlForm) {
            return;
        }

        const body = {
            remark: _.get(this.jtlForm, 'formModel.remark'),
            multipartFile: _.get(this.jtlForm, 'formModel.file')
        };
        this.startFullScreenLoading('正在导入，请耐心等待......');
        return new Promise((resolve, reject) => {
            this.jtlForm.submitForm().then((ret) => {
                new AppBatchUpdateService().importTemplate(body).then(res => {
                    if (res) {
                        this.showMessageSuccess('导入成功');
                    } else {
                        this.showMessageError('导入失败');
                    }
                    this.dialogClose();
                    this.$emit('dialogOK');
                    resolve(null);
                }).catch(err => {
                    reject();
                    throw err;
                });
            }).catch(err => {
                reject();
                throw err;
            });
        }).finally(() => {
            this.stopFullScreenLoading();
        });
    }
}


































































import { Component, Mixins } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { AppBatchUpdateEntityModel, AppBatchUpdateQueryModel } from '@common-src/entity-model/app-batch-update-entity';
import { AppBatchUpdateService } from '@common-src/service3/app-batch-update';
import DeployAppDialog from './deploy-app-dialog.vue';
import { ViewModeType } from '@common-src/model/enum';
import GroupComponent from '@common-src/mixins/group-component';
import ImportAppUpdateDialog from './import-app-update-dialog.vue';
import AppUpdataAddDialog from './app-update-add-dialog.vue';

@Component({
    components: {
        'import-add-update-dialog': ImportAppUpdateDialog,
        'app-updata-add-dialog': AppUpdataAddDialog
    }
})
export default class AppBatchUpdateComponent extends Mixins(TableDialogFormComponent, GroupComponent) {
    AppBatchUpdateEntityModel = AppBatchUpdateEntityModel;
    selectedRowKeys = [];
    created() {
        this.initTable({
            service: new AppBatchUpdateService(),
            queryModel: new AppBatchUpdateQueryModel(),
            tableColumns: AppBatchUpdateEntityModel.getTableColumns()
        });
        this.getList();
    }

    getTextColor(text:string) {
        return text === 'SUBMITTED' ? '#52c41a' : '';
    }

    addClickHander() {
        (this.$refs.appUpdataAddDialog as AppUpdataAddDialog).appUpdataAddDialogOpen(null, ViewModeType.NEW);
    }

    editClickHandler(record) {
        (this.$refs.appUpdataAddDialog as AppUpdataAddDialog).appUpdataAddDialogOpen(record, ViewModeType.UPDATE);
    }

    showClickHandler(record) {
        (this.$refs.appUpdataAddDialog as AppUpdataAddDialog).appUpdataAddDialogOpen(record, ViewModeType.VIEW);
    }

    submitClick(record) {
        new AppBatchUpdateService().submitUpgrade(record.id).then((res) => {
            this.showMessageSuccess('提交成功');
            this.getList();
        });
    }

    downloadTemplate() {
        new AppBatchUpdateService().exportTemplate().then((res) => {
            this.showMessageSuccess('下载模板成功');
        });
    }

    exportTemplate() {
        (this.$refs.importAppUpdateDialog as ImportAppUpdateDialog).dialogOpen();
    }
}


import BaseCRUDQService from '@common-src/service3/base-crudq.service';
import { AppBatchUpdateEntityModel, AppBatchUpdateQueryModel } from '@common-src/entity-model/app-batch-update-entity';
import { download, post } from '@common-src/service/request';

export class AppBatchUpdateService extends BaseCRUDQService<AppBatchUpdateEntityModel, AppBatchUpdateQueryModel> {
    constructor(baseUrl = AppBatchUpdateEntityModel.baseUrl) {
        super(baseUrl, AppBatchUpdateEntityModel);
    }

    async exportTemplate(): Promise<any> {
        const url = `${AppBatchUpdateEntityModel.baseUrl}/export/template`;
        const res = await download(url, undefined, 'POST');
        return res;
    }

    async importTemplate(data: any): Promise<any> {
        const url = `${AppBatchUpdateEntityModel.baseUrl}/import`;
        const formData = new FormData();
        formData.append('multipartFile', _.get(data, 'multipartFile.[0].file'));
        formData.append('remark', _.get(data, 'remark'));
        const res = await post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' }, timeout: 600000, responseType: 'blob' });
        return res;
    }

    async addUpgrade(data: any): Promise<any> {
        const url = `${AppBatchUpdateEntityModel.baseUrl}/add`;
        const res = await post(url, data);
        return res;
    }

    async updateUpgrade(data: any): Promise<any> {
        const url = `${AppBatchUpdateEntityModel.baseUrl}/update`;
        const res = await post(url, data);
        return res;
    }

    async submitUpgrade(id: any): Promise<any> {
        const url = `${AppBatchUpdateEntityModel.baseUrl}/submit/${id}`;
        const res = await post(url);
        return res;
    }
}

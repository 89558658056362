import { BaseEntityModel } from '@common-src/model/base-model';
import { QueryPageModel } from '@common-src/model/query-model';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { dateFormat } from '@common-src/filter';
import { Moment } from 'moment';

export class AppBatchUpdateEntityModel extends BaseEntityModel {
    static baseUrl = `/infra-appmgr/api/app/upgrade`;

    id: string = undefined;
    status: string = undefined;
    totalNum: string = undefined;
    successNum: string = undefined;
    failNum: string = undefined;
    submitTime: number = undefined;
    createTime: number = undefined;
    details: any = undefined;
    remark: any = undefined;

    webStatus: any = undefined;
    webErrMsg: any = undefined;
    mpErrMsg: any = undefined;
    mpStatus: any = undefined;
    backendErrMsg: any = undefined;
    backendStatus: any = undefined;
    static getTableColumns() {
        return [
            {
                title: '创建时间',
                dataIndex: 'createTime',
                customRender: (text) => {
                    return dateFormat(text);
                }
            },
            {
                title: '提交时间',
                dataIndex: 'submitTime',
                customRender: (text) => {
                    return dateFormat(text);
                }
            },
            {
                title: '升级说明',
                dataIndex: 'remark'
            },
            {
                title: '状态',
                dataIndex: 'status',
                scopedSlots: { customRender: 'status' }
            },
            {
                title: '服务/应用总数',
                dataIndex: 'totalNum'
            },
            {
                title: '升级成功数',
                dataIndex: 'successNum'
            },
            {
                title: '升级失败数',
                dataIndex: 'failNum'
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        return this;
    }

    toService() {
        const data: any = super.toService();
        return data;
    }
}

export class AppBatchUpdateQueryModel extends QueryPageModel {
    @QueryControl({
        label: '创建时间',
        type: QueryControlType.SELECT_DATE,
        span: 8
    })
    createTime: Array<Moment> = undefined;

    @QueryControl({
        label: '提交时间',
        type: QueryControlType.SELECT_DATE,
        span: 8
    })
    submitTime: Array<Moment> = undefined;

    toService() {
        const data: any = super.toService();
        if (!_.isEmpty(this.createTime)) {
            data.createTimeStart = this.createTime[0].startOf('day').format('YYYY-MM-DD HH:mm:ss');
            data.createTimeEnd = this.createTime[1].endOf('day').format('YYYY-MM-DD HH:mm:ss');
        }
        if (!_.isEmpty(this.submitTime)) {
            data.submitTimeStart = this.submitTime[0].startOf('day').format('YYYY-MM-DD HH:mm:ss');
            data.submitTimeEnd = this.submitTime[1].endOf('day').format('YYYY-MM-DD HH:mm:ss');
        }
        delete data.createTime;
        delete data.submitTime;
        return {
            params: data
        };
    }
}

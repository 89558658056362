var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "form-edit-component form-edit-component-max",
      attrs: { title: _vm.dialogTitle, visible: _vm.dialogVisible },
      on: { cancel: _vm.dialogClose },
    },
    [
      _c(
        "div",
        { staticClass: "app-update-add-dialog-component" },
        [
          _c(
            "a-row",
            { staticStyle: { marginBottom: "16px" } },
            [
              _c(
                "a-col",
                { staticClass: "ant-form-item-label", attrs: { span: 3 } },
                [_vm._v("升级说明：")]
              ),
              _c(
                "a-col",
                { attrs: { span: 19 } },
                [
                  _c("a-textarea", {
                    attrs: {
                      placeholder: "请输入升级说明",
                      rows: 4,
                      disabled: _vm.dynamicDisabled,
                    },
                    model: {
                      value: _vm.remark,
                      callback: function ($$v) {
                        _vm.remark = $$v
                      },
                      expression: "remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { staticClass: "ant-form-item-label", attrs: { span: 3 } },
                [
                  _c(
                    "label",
                    {
                      staticClass: "ant-form-item-required",
                      attrs: { title: "升级明细" },
                    },
                    [_vm._v("升级明细")]
                  ),
                ]
              ),
              _c(
                "a-col",
                { attrs: { span: 19 } },
                [
                  !_vm.dynamicDisabled
                    ? _c(
                        "jtl-button",
                        {
                          staticClass: "jtl-link",
                          on: {
                            click: function ($event) {
                              return _vm.addUpdateResource()
                            },
                          },
                        },
                        [_vm._v("+添加")]
                      )
                    : _vm._e(),
                  _c(
                    "a-table",
                    {
                      attrs: {
                        columns: _vm.columns,
                        "data-source": _vm.dataSource,
                        pagination: false,
                        "row-key": "id",
                      },
                      scopedSlots: _vm._u(
                        [
                          _vm._l(_vm.dataSourceKey, function (item, index) {
                            return {
                              key: item,
                              fn: function (text, record) {
                                return [
                                  item === "backendImage"
                                    ? _c(
                                        "a-input",
                                        {
                                          attrs: {
                                            disabled: _vm.dynamicDisabled,
                                            title: record[item],
                                            placeholder: "请输入",
                                          },
                                          model: {
                                            value: record[item],
                                            callback: function ($$v) {
                                              _vm.$set(record, item, $$v)
                                            },
                                            expression: "record[item]",
                                          },
                                        },
                                        [
                                          record.backendStatus === "SUCCESS"
                                            ? _c("a-icon", {
                                                staticStyle: {
                                                  color: "#52c41a",
                                                },
                                                attrs: {
                                                  slot: "addonAfter",
                                                  type: "check-circle",
                                                  theme: "filled",
                                                },
                                                slot: "addonAfter",
                                              })
                                            : _vm._e(),
                                          record.backendStatus === "FAIL"
                                            ? _c("a-icon", {
                                                staticStyle: {
                                                  color: "#ff4400",
                                                },
                                                attrs: {
                                                  slot: "addonAfter",
                                                  type: "close-circle",
                                                  theme: "filled",
                                                },
                                                slot: "addonAfter",
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : item === "webImage"
                                    ? _c(
                                        "a-input",
                                        {
                                          attrs: {
                                            title: record[item],
                                            disabled: _vm.dynamicDisabled,
                                            placeholder: "请输入",
                                          },
                                          model: {
                                            value: record[item],
                                            callback: function ($$v) {
                                              _vm.$set(record, item, $$v)
                                            },
                                            expression: "record[item]",
                                          },
                                        },
                                        [
                                          record.webStatus === "SUCCESS"
                                            ? _c("a-icon", {
                                                staticStyle: {
                                                  color: "#52c41a",
                                                },
                                                attrs: {
                                                  slot: "addonAfter",
                                                  type: "check-circle",
                                                  theme: "filled",
                                                },
                                                slot: "addonAfter",
                                              })
                                            : _vm._e(),
                                          record.webStatus === "FAIL"
                                            ? _c("a-icon", {
                                                staticStyle: {
                                                  color: "#ff4400",
                                                },
                                                attrs: {
                                                  slot: "addonAfter",
                                                  type: "close-circle",
                                                  theme: "filled",
                                                },
                                                slot: "addonAfter",
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : item === "mpImage"
                                    ? _c(
                                        "a-input",
                                        {
                                          attrs: {
                                            title: record[item],
                                            disabled: _vm.dynamicDisabled,
                                            placeholder: "请输入",
                                          },
                                          model: {
                                            value: record[item],
                                            callback: function ($$v) {
                                              _vm.$set(record, item, $$v)
                                            },
                                            expression: "record[item]",
                                          },
                                        },
                                        [
                                          record.mpStatus === "SUCCESS"
                                            ? _c("a-icon", {
                                                staticStyle: {
                                                  color: "#52c41a",
                                                },
                                                attrs: {
                                                  slot: "addonAfter",
                                                  type: "check-circle",
                                                  theme: "filled",
                                                },
                                                slot: "addonAfter",
                                              })
                                            : _vm._e(),
                                          record.mpStatus === "FAIL"
                                            ? _c("a-icon", {
                                                staticStyle: {
                                                  color: "#ff4400",
                                                },
                                                attrs: {
                                                  slot: "addonAfter",
                                                  type: "close-circle",
                                                  theme: "filled",
                                                },
                                                slot: "addonAfter",
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _c("a-input", {
                                        attrs: {
                                          disabled: _vm.dynamicDisabled,
                                          title: record[item],
                                          placeholder: "请输入",
                                        },
                                        model: {
                                          value: record[item],
                                          callback: function ($$v) {
                                            _vm.$set(record, item, $$v)
                                          },
                                          expression: "record[item]",
                                        },
                                      }),
                                ]
                              },
                            }
                          }),
                          {
                            key: "action",
                            fn: function (text, record) {
                              return !_vm.dynamicDisabled
                                ? [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "jtl-del-link",
                                        on: {
                                          click: function ($event) {
                                            return _vm.delRecord(record)
                                          },
                                        },
                                      },
                                      [_vm._v("删除")]
                                    ),
                                  ]
                                : undefined
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "ant-form-item-required",
                          attrs: { slot: "customName", title: "应用/服务名称" },
                          slot: "customName",
                        },
                        [_vm._v("应用/服务名称")]
                      ),
                      _c(
                        "label",
                        {
                          staticClass: "ant-form-item-required",
                          attrs: { slot: "customIdentifier", title: "标识符" },
                          slot: "customIdentifier",
                        },
                        [_vm._v("标识符")]
                      ),
                      _c(
                        "label",
                        {
                          staticClass: "ant-form-item-required",
                          attrs: {
                            slot: "customVersion",
                            title: "应用/服务版本",
                          },
                          slot: "customVersion",
                        },
                        [_vm._v("应用/服务版本")]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "jtl-button",
            { attrs: { "click-prop": _vm.dialogClose.bind(this) } },
            [_vm._v("取消")]
          ),
          !_vm.dynamicDisabled
            ? _c(
                "jtl-button",
                {
                  attrs: {
                    type: "primary",
                    "click-prop": _vm.dialogOK.bind(this),
                  },
                },
                [_vm._v("确定")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }



















































































import { Component } from 'vue-property-decorator';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import { ICRUDQ } from '@common-src/model/interface';
import { ViewModeType } from '@common-src/model/enum';
import { AppBatchUpdateService } from '@common-src/service3/app-batch-update';
import { FormControlType } from '@common-src/model/form-control';
import { generateUUID } from '@common-src/utils/base_util';

@Component
export default class AppUpdataAddDialog extends FormDialogComponent<any> {
    public columns = [
        {
            dataIndex: 'name',
            slots: { title: 'customName' },
            scopedSlots: { customRender: 'name' }
        },
        {
            dataIndex: 'identifier',
            slots: { title: 'customIdentifier' },
            scopedSlots: { customRender: 'identifier' }
        },
        {
            dataIndex: 'version',
            slots: { title: 'customVersion' },
            scopedSlots: { customRender: 'version' }
        },
        {
            title: '后端服务镜像版本',
            dataIndex: 'backendImage',
            scopedSlots: { customRender: 'backendImage' }
        },
        {
            title: 'web镜像版本',
            dataIndex: 'webImage',
            scopedSlots: { customRender: 'webImage' }
        },
        {
            title: '小程序镜像版本',
            dataIndex: 'mpImage',
            scopedSlots: { customRender: 'mpImage' }
        },
        {
            title: '',
            dataIndex: 'action',
            width: 80,
            scopedSlots: { customRender: 'action' }
        }
    ];
    public dataSourceKey = ['id', 'name', 'identifier', 'version', 'backendImage', 'webImage', 'mpImage'];
    // 表格数据
    public dataSource = [];
    public remark = null;
    public model:any = undefined;
    public dynamicDisabled = false;
    public requireKey = ['name', 'identifier', 'version'];

    appUpdataAddDialogOpen(model:any, viewMode:ViewModeType): void {
        this.dialogVisible = true;
        this.viewMode = viewMode;
        this.initDialogTitle(this.viewMode);
        this.dynamicDisabled = this.viewMode === ViewModeType.VIEW;
        this.dataSource = [];
        this.remark = null;
        if (model) {
            this.model = model;
            this.getDetail(model.id);
        } else {
            this.addUpdateResource();
        }
    }

    getDetail(id) {
        new AppBatchUpdateService().retrieve(id).then(res => {
            this.dataSource = res.details;
            this.remark = res.remark;
        });
    }

    validateData(data:any) {
        return _.every(data, (obj:any) => {
            return _.every(this.requireKey, (field:string) => {
                return !_.isEmpty(_.get(obj, field));
            });
        });
    }

    dialogOK(): any {
        const body = {
            id: _.get(this.model, 'id'),
            remark: this.remark,
            details: _.map(this.dataSource, r => {
                r.id = _.includes(r.id, 'default') ? '' : r.id;
                return { ...r };
            })
        };

        if (!this.validateData(body.details)) {
            this.showMessageError('请检查您提交的数据');
            return;
        }
        if (this.viewMode === ViewModeType.NEW) {
            return new Promise((resolve, reject) => {
                new AppBatchUpdateService().addUpgrade(body).then(res => {
                    this.showMessageSuccess(`${this.dialogTitle}成功`);
                    this.dialogClose();
                    this.$emit('dialogOK');
                    resolve(null);
                }).catch(err => {
                    reject();
                    throw err;
                });
            });
        } else {
            return new Promise((resolve, reject) => {
                new AppBatchUpdateService().updateUpgrade(body).then(res => {
                    this.showMessageSuccess(`${this.dialogTitle}成功`);
                    this.dialogClose();
                    this.$emit('dialogOK');
                    resolve(null);
                }).catch(err => {
                    reject();
                    throw err;
                });
            });
        }
    }

    addUpdateResource() {
        this.dataSource.push(_.reduce(this.dataSourceKey, (result, key) => {
            result[key] = '';
            if (key === 'id') {
                result[key] = generateUUID() + 'default';
            }
            return result;
        }, {}));
    }

    delRecord(model:any) {
        const index = _.findIndex(this.dataSource, r => r.id === model.id);
        if (index > -1) {
            this.dataSource.splice(index, 1);
        }
    }
}
